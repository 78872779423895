import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'font-awesome/css/font-awesome.min.css';

// Main has to be imported as lazy component to be able to inject style from App
const Main = React.lazy(() => import('./App'));

createRoot(document.getElementById('root') as HTMLElement).render(
  <Suspense fallback={<div />}>
    <Main />
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
